document.addEventListener("DOMContentLoaded", function(){
    
    // Number Counters
    let options = {
        startVal: 0,
        decimalPlaces: 0,
        duration: 2,
    };
    
    let observer = new IntersectionObserver(function (entries, self) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                let counter = entry.target;
                let endVal = parseInt(counter.getAttribute('data-count'));
                let countup = new CountUp(counter, endVal, options);
                if (!countup.error) {
                    countup.start();
                } else {
                    console.error(countup.error);
                }
                self.unobserve(counter);
            }
        });
    });
    
    document.querySelectorAll('.counter').forEach(counter => {
        observer.observe(counter);
    });
    
    // Testimonial Slider
    var swiper = new Swiper('#testimonials-swiper-container', {
        // Show only one slide at a time
        slidesPerView: 1,
        spaceBetween: 10,
    
        // Add pagination dots
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    
        // Optional parameters
        direction: 'horizontal',
        loop: true,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    
        // Form Button Toggle 
        document.querySelectorAll('#formButtons label').forEach(button => {
            button.addEventListener('click', () => {
                document.querySelectorAll('#formButtons label').forEach(btn => {
                    btn.classList.remove('bg-white');
                    btn.classList.remove('text-blue-custom');
                    btn.classList.add('text-white');
                });
                button.classList.add('bg-white');
                button.classList.remove('text-white');
                button.classList.add('text-blue-custom');
            });
        });

        // Form Submission
        jQuery('.wpcf7-form').on('submit', function(e) {
            console.log('Form submitted!');
            e.preventDefault();
            let $form = $(this);
        
            let data = {
                name: $('#name').val(),
                phone: $('#phone').val(),
                email: $('#email').val(),
                location: $('#location').val(),
                requirements: $('#requirements').val(),
                additional_details: $('#additional-details').val(),
            };
        
            $.ajax({
                url: '/wp-json/contact-form-7/v1/contact-forms/10/feedback',
                data: data,
                type: 'POST',
                success: function(response) {
                    if(response.status === 'mail_sent') {
                        alert('Mail sent!');
                    } else {
                        alert('Mail not sent!');
                    }
                },
                error: function() {
                    alert('An error occurred!');
                },
            });
        });


}); //end DOM Load